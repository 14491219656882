import { MantineFontSize, StyleProp, Text } from '@mantine/core';
import { getFontWeight } from '../helpers/dataH';

interface Props {
    text: any
    textSize?: MantineFontSize;
    textWeight?: 'light' | 'medLight' | 'med' | 'medHeavy' | 'heavy'
    color?: string
    onClick?: any
    cursor?: 'pointer' | 'default' | 'text' 
    align?: StyleProp<React.CSSProperties['textAlign']>;
}

export default function TextField(props: Props) {

    return (
        <Text onClick={props.onClick} size={props.textSize} ta={props.align ?? "justify"} className={`cursor-${props.cursor || 'pointer'}`} c={props.color} fw={getFontWeight(props.textWeight)}>{props.text}</Text>
    )
}

