import { Button as ReactButton, StyleProp } from '@mantine/core';
import { getButtonColor } from '../helpers/dataH';
import './lowlevel.scss'

interface Props {
    text: string | undefined
    isDisable?: boolean
    isLoading?: boolean
    fullWidth?: boolean
    onClick?: () => void
    color?: "Primary" | "Secondary" | "Teritary" | "Danger"
    width?: string
    className?: string
    leftSection?: any
    justify?: 'left' | 'right'
    maxWidth?: StyleProp<React.CSSProperties['maxWidth']>
}

export default function Button(props: Props) {


    const getButtonJustify = (type: string = "right") => {
        switch (type) {
            case "left":
                return 'justifyLeft'
            case "right":
                return 'justifyRight'
            default:
                return 'justifyRight'
        }
    }

    return <ReactButton miw="10rem" maw={props.maxWidth} leftSection={props.leftSection} w={props.width} className={getButtonJustify(props.justify)} onClick={props.onClick} loading={props.isLoading} disabled={props.isDisable} size="sm" radius="xl" fullWidth={props.fullWidth} color={getButtonColor(props.color)} >{props.text}  </ReactButton>
}