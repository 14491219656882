import { MSP, PSA, REST, endpoints, urls } from '../constants'
import { ApiUrlParams, DiffEndpoint } from '../types/Api'

const processApiResponse = async (response: any, isDomainVerificationCall: boolean = false): Promise<any> => {
    if (isDomainVerificationCall === false) {
        const userToken = sessionStorage.getItem('userToken') as string
        if (response[0]?.oStatus === '0') {
            const tokenIsValid = await isUserTokenValid(userToken)
            if (tokenIsValid === false) {
                sessionStorage.setItem('userToken', '2')
                window.location.href = '/login'
            }
        }
    }
    return response
}

export const preparePromise = (
    type: apiType,
    dataSource: string,
    params?: Record<string, string>,
    otherOptions?: {
        skipToken?: boolean
        diffEndpoint?: DiffEndpoint
        isDomainVerificationCall?: boolean
        isBlob?: boolean
        respWithStatusCode?: boolean
    }
) => {
    const userToken = sessionStorage.getItem('userToken')
    const payload = {
        ...params,
    }

    // If skipToken is true or iTOKEN is passed then we'll not pass the token from session in payload
    if (!otherOptions?.skipToken && !payload.iTOKEN && userToken) {
        payload['iTOKEN'] = userToken
    }

    const queryParams = packParams(payload)

    if (type === apiType.get) {
        return apiGet(dataSource, queryParams, otherOptions).then((resp) =>
            processApiResponse(resp, otherOptions?.isDomainVerificationCall)
        )
    } else {
        return apiPost(dataSource, payload).then((resp) =>
            processApiResponse(resp, otherOptions?.isDomainVerificationCall)
        )
    }
}

function apiPost(
    sp: string,
    requestBody?: Record<string, string>,
    otherOptions?: { signal?: AbortSignal }
): Promise<any> {
    let url: string = generateRestApiUrl(sp, REST)

    const requestPayload: Record<string, any> = {
        method: 'POST',
    }
    if (otherOptions?.signal) {
        requestPayload['signal'] = otherOptions.signal
    }
    const formData = new FormData()
    for (const key in requestBody) {
        formData.set(key, requestBody[key])
    }

    if (requestBody) {
        requestPayload['body'] = formData
    }

    return fetch(url, requestPayload)
        .then((resp) => {
            return resp.json()
        })
        .catch((res) => {
            // This is to prevent api calls without error handling from crashing everything.
            return
        })
}

export function isUserTokenValid(userToken: string) {
    return new Promise((resolve) => {
        const params = packParams({
            iTOKEN: userToken,
        })
        apiGet('_ASYS064', params).then((resp) => {
            if (resp[0].isValid === '1') {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    })
}

function apiGet(
    sp: string,
    params: string,
    otherOptions?: { diffEndpoint?: DiffEndpoint; signal?: AbortSignal; isBlob?: boolean; respWithStatusCode?: boolean }
): Promise<any> {
    let url: string = generateRestApiUrl(sp, REST)
    const init: RequestInit = {
        method: 'GET',
    }
    if (otherOptions) {
        if (otherOptions.diffEndpoint) {
            url = generateRestApiUrl(sp, otherOptions.diffEndpoint)
        }
        if (otherOptions.signal) {
            init['signal'] = otherOptions.signal
        }
    }

    url += params

    return fetch(url, init)
        .then((resp) => {
            if (otherOptions?.isBlob || otherOptions?.respWithStatusCode) return resp
            return resp.json()
        })
        .catch((e) => {
            // This is to prevent api calls without error handling from crashing everything.
            console.log('Problem when trying to make this call: ' + url)
            if (otherOptions?.respWithStatusCode) {
                return e
            }
        })
}


export const getRESTAPI = (url: string) => {
    return fetch(url).then((resp) => {
        return resp.json()
    })
}

export function packParams(params: ApiUrlParams): string {
    const keys = Object.keys(params)
    if (keys.length === 0) return ''
    const vals = Object.values(params)
    let returnStr = `?${encodeURIComponent(keys[0])}=${encodeURIComponent(vals[0])}`

    for (let i = 1; i < keys.length; i++) {
        returnStr += `&${encodeURIComponent(keys[i])}=${encodeURIComponent(vals[i])}`
    }
    return returnStr
}

export enum apiType {
    get = 'GET',
    post = 'POST',
    delete = 'DELETE',
}

export function getUrlHost(): string {
    if (isSessionLocal()) {
        return urls.localhost
    } else {
        return urls.thisInstance
    }
}

export function isSessionLocal(): boolean {
    if (window.location.hostname === 'localhost') {
        return true
    } else {
        return false
    }
}

export function generateRestApiUrl(storedProcedure: string, endpoint: string): string {
    const url = getUrlHost()

    switch (endpoint) {
        case MSP:
            return url + endpoints.msp + storedProcedure
        case REST:
            return url + endpoints.rest + storedProcedure
        case PSA:
            return url + endpoints.psa + storedProcedure
        default:
            return 'badurl'
    }
}

export const generateMfaUrl = (): string => {
    const url: string = getUrlHost()
    return url + endpoints.mfa
}
