const charcoal = "#36454f"
const cyrismaOrange = '#ff9000'
const davyGrey = '#535353'
const white = "#fff"
const skyBlue = "#3887e5"
const antiFlashWhite = '#F0F2FA'
const veryLightGrey = "#f2f2f2"
const softOrange = "#ffA954"
const lavender = "#a9aee2"
const purple = '#2a2f49'
const navyBlue = '#526197'
const darkYellow = "#CCA20A"
const avocadoGreen = "#94aa43"
const sunsetPeach = "#ff8164"
const lightPurple = "#a679C6"
const dullYellow = "#BDC651"
const paleWhite = '#C5CEE5'
const red = '#cb444a'
const darkBlue = '#262f88'
const purpleLavender = '#7A93E5'
const lightLavender = '#A9AEE2'

export const focusStyles = {
    text: {
        colors: {
            primary: cyrismaOrange,
            secondary: skyBlue,
            tertiary: lavender,
            inactive: charcoal,
            contrast: paleWhite,
            grey: davyGrey,
            purple: purple,
            simpleWhite: white,
            navyBlue: navyBlue,
            darkBlue: darkBlue,
            purpleLavender: purpleLavender,
            lightLavender: lightLavender
        },
    },

    background: {
        primary: lavender,
        secondary: skyBlue,
        select: antiFlashWhite,
        inactive: veryLightGrey,
        lowContrast: veryLightGrey,
        simpleWhite: white,
        highContrast: purple
    },
}

const gradients = {
    blue: [
        '#caf0f8',
        '#ade8f4',
        '#90e0ef',
        '#48cae4',
        '#00b4d8',
        '#0096c7',
        '#0077b6',
        '#023e8a',
        '#03045e'
    ],
    green: [
        '#d8f3dc',
        '#b7e4c7',
        '#95d5b2',
        '#74c69d',
        '#52b788',
        '#40916c',
        '#2d6a4f',
        '#1b4332',
        '#081c15'
    ],
    red: [
        '#ffccd5',
        '#ffb3c1',
        '#ff8fa3',
        '#ff758f',
        '#ff4d6d',
        '#c9184a',
        '#a4133c',
        '#800f2f',
        '#590d22',
        '#F09CD6'
    ],
    purple: [
        '#dac3e8',
        '#d2b7e5',
        '#c19ee0',
        '#b185db',
        '#a06cd5',
        '#9163cb',
        '#815ac0',
        '#7251b5',
        '#6247aa'
    ],
}

export const buttonStyles = {
    colors: {
        primaryBg: skyBlue,
        primaryText: focusStyles.text.colors.contrast,
        secondaryBg: lavender,
        secondaryText: focusStyles.text.colors.contrast,
        tertiaryBg: cyrismaOrange,
        tertiaryText: focusStyles.text.colors.contrast,
        wireBg: 'inherit',
        wireText: skyBlue,
        wireBorderColor: skyBlue,
        danger: red
    }
}

const colors = {
    oranges: {
        softOrange: "#ffA954",
        sunsetPeach: "#ff8164",
        cyrismaOrange: '#ff9000',
    },
    pinks: {
        pink: "#e07a7a",
    },
    yellows: {
        dullYellow: dullYellow,
        darkYellow: darkYellow,
        lightYellow: '#FFFDD0',
        neonYellow: '#FFFF31',
        canaryYellow: '#FFEF00',
        goldenYellow: '#FFDF00'
    },
    greys: {
        charcoal: "#36454f",
        veryLightGrey: "#f2f2f2",
        lightGrey: "#d9d9d9",
        grey: '#b3b3b3',
        darkGrey: "#8e8e8e",
    },
    white: '#fff',
    blues: {
        cosmicColbalt: "#262f88",
        mahinaBlueGrey: "#2a2f49",
        dullGreyBlue: "#395972",
        babyBlueEye: "#9dcbf9",
        skyBlue: "#3887e5",
        silverBlue: "#828293",
    },
    greens: {
        avocadoGreen: "#94aa43",
        pearGreen: '#c5d132',
        lightMintGreen: '#d8f3dc',
        mintGreen: '#b7e4c7',
        airFreshenerGreen: '#52b788',
        deciduousGreen: '#2d6a4f',
        veryDarkGreen: '#081c15'
    },
    purples: {
        lavender: "#a9aee2",
        lightLavender: '#E5E7F7',
        lightPurple: "#a679C6"
    },
    lowRisk: avocadoGreen,
    medRisk: softOrange,
    highRisk: sunsetPeach,
    critRisk: lightPurple,
}


export const chartStyles = {
    data: {
        colors: {
            all: {
                lowRisk: colors.lowRisk,
                medRisk: colors.medRisk,
                highRisk: colors.highRisk,
                critRisk: colors.critRisk,
                ...gradients.blue,
                ...gradients.red,
                ...gradients.green,
                ...gradients.purple,
                ...colors.blues,
                ...colors.greens,
                ...colors.greys,
                ...colors.oranges,
                ...colors.pinks,
                ...colors.purples,
                ...colors.yellows,
            },
        },
    },
}
