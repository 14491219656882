import { atom } from 'jotai'
import { TreeType } from '../types/Api'

interface Instance {
    value?: string
    label?: string
}

const atomWithStorage = (key: string, initValue: string, storage: Storage) => {
    const persistingAtom = atom(storage.getItem(key) ?? initValue)
    return atom(
        (get) => get(persistingAtom),
        (get, set, value: string) => {
            set(persistingAtom, value)
            storage.setItem(key, value)
        },
    )
}

export const userTokenAtom = atomWithStorage('userToken', '1', sessionStorage)
export const usernameAtom = atomWithStorage('username', '', sessionStorage)
export const userRoleAtom = atomWithStorage('userRole', '', sessionStorage)
export const userOrganisationAtom = atomWithStorage('organization', '', sessionStorage)
export const selectedInstance = atom<Instance>({})
export const instanceNodeList = atom<TreeType[]>([])
export const orgLicenseInfoListAtom = atom<Record<string, string | number>[]>([])
export const pageRoleList = atomWithStorage('pageRole', '', sessionStorage)
export const currentPageId = atom<string>('')