import { Card, Container, MantineSize, Text } from '@mantine/core'
import { ReactElement } from 'react'
import ElemsRow from './ElemsRow'
import FlexContainer from './FlexContainer'
import { focusStyles } from '../styles/color'
import TextField from './TextField'
import LoadingSpinner from './LoadingSpinner'

interface Props {
    bgColor?: string
    headerTitle?: string
    headerContent?: ReactElement
    children?: any
    isPageContainer?: boolean
    showBorder?: boolean
    titleTextSize?: MantineSize
    cardSize?: MantineSize
    isDoneLoading?: boolean
    cardPadding?: MantineSize | string
}

export default function CardContainer({
    bgColor,
    children,
    headerTitle,
    headerContent,
    isPageContainer = false,
    showBorder = false,
    titleTextSize = 'lg',
    cardSize = "xl",
    isDoneLoading,
    cardPadding
}: Props) {

    const cardProps = {
        bg: bgColor || focusStyles.background.simpleWhite,
        padding: cardPadding || 'lg',
        radius: 'xl',
        withBorder: showBorder
    }

    const containerProps = {
        w: "100%",
        px: "0",
        fluid: !isPageContainer,
        size: cardSize
    }

    return (
        <Container {...containerProps}>
            {isDoneLoading ? <LoadingSpinner type='pageBody'/> :
                <Card {...cardProps}>
                    <ElemsRow numCols={1}>
                        {headerTitle || headerContent ? (
                            <ElemsRow numCols={2}>
                                <FlexContainer justifyContent="left">
                                    {headerTitle && (
                                        <TextField text={headerTitle} textSize={titleTextSize} color={focusStyles.text.colors.grey} textWeight="heavy" align="start" />
                                    )}
                                </FlexContainer>
                                {headerContent && <FlexContainer justifyContent="right">{headerContent}</FlexContainer>}
                            </ElemsRow>
                        ) : null}
                        {children}
                    </ElemsRow>
                </Card>
            }
        </Container>
    )
}
