import { ToastPosition, toast } from 'react-toastify';

const defaultSetting = { position: 'bottom-right' as ToastPosition }

export const notify = {
    error: function (message: string, userMustDismiss?: true) {
        toast.error(message, { ...defaultSetting })
    },
    genericError: function () {
        toast.error('An error occured. Please refresh the page or submit a ticket')
    },
    success: function (message: string, userMustDismiss?: true) {
        toast.success(message, {
            ...defaultSetting
        })
    },
    warning: function (message: string, userMustDismiss?: true) {
        toast.warning(message, {
            ...defaultSetting
        })
    }
}