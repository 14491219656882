import React, { Component, ReactNode } from 'react'
import FlexContainer from '../lowLevel/FlexContainer'
import { Button, Container, Flex } from '@mantine/core'
import ElemsRow from '../lowLevel/ElemsRow'
import { ReactComponent as ErrorPage } from '../assets/errorpage.svg'
import CardContainer from '../lowLevel/CardContainer'
import TextField from '../lowLevel/TextField'
import { focusStyles } from '../styles/color'

interface Props {
    setUserToken: (token: string) => void
    children?: ReactNode
}

interface State {
    error: Error | null
    hasError: boolean
}

export default class ErrorBoundary extends Component<Props, State> {
    state: State = {
        error: null,
        hasError: false,
    }

    static getDerivedStateFromError(error: Error): State {
        return { error, hasError: true }
    }

    componentDidCatch(error: Error) {
        console.error('Uncaught error:', error)
    }

    componentWillUnmount(): void {
        this.resetStates()
    }

    refresh = () => {
        this.resetStates()
        window.location.reload()
    }

    logout = () => {
        this.resetStates()
        this.props.setUserToken('')
    }

    resetStates = () => {
        this.setState({ error: null, hasError: false })
    }

    renderForm = () => {
        return (
            <FlexContainer direction="column" gap="4rem" px="xl">
                <TextField
                    text="An unknown error occurred!"
                    textWeight="medHeavy"
                    textSize="3rem"
                    color={focusStyles.text.colors.simpleWhite}
                />
                <FlexContainer gap="xl">
                    <Button size="lg" radius="xl" w="16rem" variant="white" onClick={this.refresh}>
                        Reload Screen
                    </Button>
                    <Button size="lg" radius="xl" w="16rem" variant="white" onClick={this.logout}>
                        Login Again
                    </Button>
                </FlexContainer>
                {this.state.error?.message && (
                    <CardContainer >
                        <TextField textSize="lg" color={focusStyles.text.colors.grey} textWeight="medHeavy" text={this.state.error.message} />
                        {this.state.error.stack && <TextField textSize="sm" color={focusStyles.text.colors.grey} text={this.state.error.stack} />}
                    </CardContainer>
                )}
            </FlexContainer>
        )
    }

    // getSupportInfoText = () => {
    //     return (
    //         <>
    //             If your problem is still unresolved, please click&nbsp;
    //             <a href="https://cyrisma.supportsystem.com/" target="_blank" rel="noreferrer">
    //                 here
    //             </a>
    //             &nbsp;to raise a ticket
    //         </>
    //     )
    // }

    render() {
        if (this.state.hasError) {
            return (
                <Flex bg="#3887e5" h="100vh">
                    <ElemsRow numCols={2}>
                        <FlexContainer>
                            <ErrorPage width="80%" />
                        </FlexContainer>
                        <FlexContainer>{this.renderForm()}</FlexContainer>
                    </ElemsRow>
                </Flex>
            )
        }

        return this.props.children
    }
}
