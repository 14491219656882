import { Icompany } from '../lowLevel/Mapper'
import { buttonStyles, focusStyles } from '../styles/color'
import { theme } from '../styles/theme'
import { ApiData, DropdownType } from '../types/Api'
import { GradeProps } from '../types/Props'
import { apiType, preparePromise } from './ajaxH'
import { notify } from './notifyH'

const gradeAndColorMap = {
    "-": "#BBBBBB",
    "A+": "#375623",
    "A": "#375623",
    "A-": "#375623",
    "B+": "#92D050",
    "B": "#92D050",
    "B-": "#92D050",
    "C+": "#FF9900",
    "C": "#FF9900",
    "C-": "#FF9900",
    "D+": "#FF7700",
    "D": "#FF6600",
    "D-": "#FF4400",
    "F": "#FF0000"
};

export const getGradeAndColorFromScore = (score: any) => {
    if (!score || score === "-") {
        return {
            grade: "-",
            color: gradeAndColorMap["-"]
        };
    } else if (score <= 1.00) {
        return {
            grade: "A+",
            color: gradeAndColorMap["A+"]
        };
    } else if (score <= 1.33) {
        return {
            grade: "A",
            color: gradeAndColorMap["A"]
        };
    } else if (score <= 1.67) {
        return {
            grade: "A-",
            color: gradeAndColorMap["A-"]
        };
    } else if (score <= 2.00) {
        return {
            grade: "B+",
            color: gradeAndColorMap["B+"]
        };
    } else if (score <= 2.33) {
        return {
            grade: "B",
            color: gradeAndColorMap["B"]
        };
    } else if (score <= 2.67) {
        return {
            grade: "B-",
            color: gradeAndColorMap["B-"]
        };
    } else if (score <= 3.00) {
        return {
            grade: "C+",
            color: gradeAndColorMap["C+"]
        };
    } else if (score <= 3.33) {
        return {
            grade: "C",
            color: gradeAndColorMap["C"]
        };
    } else if (score <= 3.67) {
        return {
            grade: "C-",
            color: gradeAndColorMap["C-"]
        };
    } else if (score <= 4.00) {
        return {
            grade: "D+",
            color: gradeAndColorMap["D+"]
        };
    } else if (score <= 4.33) {
        return {
            grade: "D",
            color: gradeAndColorMap["D"]
        };
    } else if (score <= 4.67) {
        return {
            grade: "D-",
            color: gradeAndColorMap["D-"]
        };
    } else {
        return {
            grade: "F",
            color: gradeAndColorMap["F"]
        };
    }
}

export const getButtonColor = (type: string = 'Primary') => {
    switch (type) {
        case 'Primary':
            return buttonStyles.colors.primaryBg
        case 'Secondary':
            return buttonStyles.colors.secondaryBg
        case 'Teritary':
            return buttonStyles.colors.tertiaryBg
        case 'Danger':
            return buttonStyles.colors.danger
        default:
            return buttonStyles.colors.primaryBg
    }
}

export const getFontWeight = (type: string = 'medLight') => {
    switch (type) {
        case 'light':
            return theme.other?.fontWeight.light
        case 'medLight':
            return theme.other?.fontWeight.medLight
        case 'med':
            return theme.other?.fontWeight.med
        case 'medHeavy':
            return theme.other?.fontWeight.medHeavy
        case 'heavy':
            return theme.other?.fontWeight.heavy
        default:
            return theme.other?.fontWeight.medLight
    }
}

export const getLabelColor = (type: string = 'Primary') => {
    switch (type) {
        case 'Primary':
            return focusStyles.text.colors.grey
        case 'Secondary':
            return focusStyles.text.colors.contrast
        default:
            return focusStyles.text.colors.grey
    }
}

export function convertLetterGradeToNumber(grade: any) {
    switch (grade) {
        case 'A+':
            return 90
        case 'A':
            return 85
        case 'A-':
            return 80
        case 'B+':
            return 70
        case 'B':
            return 65
        case 'B-':
            return 60
        case 'C+':
            return 50
        case 'C':
            return 45
        case 'C-':
            return 40
        case 'D+':
            return 30
        case 'D':
            return 25
        case 'D-':
            return 20
        case 'F':
            return 10
        case '-':
            return 0
    }
}

export function convertNumberGradeToLetter(grade: GradeProps['numberGrades']): GradeProps['letterGrades'] {
    switch (grade) {
        case 90:
            return 'A+'
        case 85:
            return 'A'
        case 80:
            return 'A-'
        case 70:
            return 'B+'
        case 65:
            return 'B'
        case 60:
            return 'B-'
        case 50:
            return 'C+'
        case 45:
            return 'C'
        case 40:
            return 'C-'
        case 30:
            return 'D+'
        case 25:
            return 'D'
        case 20:
            return 'D-'
        case 10:
            return 'F'
        case 0:
            return '-'
    }
}

export function sortDataInOrder(arr: ApiData[], key: string, order?: 'ASC' | 'DESC') {
    if (order === 'DESC') {
        return arr.sort((a: ApiData, b: ApiData) => b[key].localeCompare(a[key]))
    }
    return arr.sort((a: ApiData, b: ApiData) => a[key].localeCompare(b[key]))
}

export function jsonParser(json: string) {
    try {
        return JSON.parse(json)
    } catch (error) {
        return []
    }
}

export function findChildrenById(data: any, value: string): Icompany[] {
    if (!value) return [];

    const result: Icompany[] = [];


    function getAllNodeCompanyList(nodeChilds: any[], parentName: string){
        const parentPath = parentName ? parentName + ' / ' : ''
        nodeChilds.forEach((child) => {
            if(child.isPSAConfigured === ''){
                result.push({ name: parentPath + child.label, id: child.value })
                if(child.isMSP === '1'){
                    getAllNodeCompanyList(child.children, parentPath + child.label)
                }
            }
        })

    }

    function search(tree: any): boolean {
        for (const node of tree) {
            if (node.value === value) {
                result.push({ id: node.value, name: node.label });
                getAllNodeCompanyList(node.children, '')
                return true;
            }
            if (node.children && search(node.children)) {
                return true;
            }
        }
        return false;
    }

    search(data);
    return result;
}

export const processDropdownData = (resp: ApiData[], labelName: string, valueName: string) => {
    let data: DropdownType[] = []
    resp?.forEach((item: ApiData) => {
        data.push({
            value: String(item[valueName]),
            label: String(item[labelName]),
        })
    })
    return data
}

export const getTotalInstances = (data: any, prevCount?: any) => {
    let count = prevCount ?? 0
    if (data.children?.length > 0) {
        for (var i = 0; i < data.children.length; i++) {
            count += getTotalInstances(data.children[i], prevCount);
        }
    } else {
        count += 1;
    }

    return count;
}

export const getCombinedCount = (data: any, key?: any) => {
    let count = Number(data[key] || 0)
    if (data.children?.length > 0) {
        for (var i = 0; i < data.children.length; i++) {
            count += getCombinedCount(data.children[i], key)
        }
    }
    return count
}

export const getIndustryRepresented = (data: any, prevIndustryRepresented?: any) => {
    const industryRepresented = prevIndustryRepresented ?? []

    if (industryRepresented.includes(data.oIndType) === false) {
        industryRepresented.push(data.oIndType);
    }

    if (data.children?.length > 0) {
        for (var i = 0; i < data.children.length; i++) {
            getIndustryRepresented(data.children[i], industryRepresented);
        }
    }

    return industryRepresented;
}

function setDefaultArgsIfNoneProvided(defaultArgObj: any, inputArgObj: any) {
    if (typeof inputArgObj == 'undefined') {
        return defaultArgObj
    }

    let returnArgs = defaultArgObj
    for (const prop in defaultArgObj) {
        if (typeof inputArgObj[prop] != 'undefined' && inputArgObj[prop] != null) {
            returnArgs[prop] = inputArgObj[prop]
        }
    }
    return returnArgs
}

export function exportCsv(colKeys: any, data: any, headers: any, fileName: any, options: any) {
    try {
        // "stripJsonLikeWeirdness" refers to some of the API returns that have a bunch of stuff to make it work in data tables, or something. VSCN022A.oCVETests is an example.
        const defaultOptions = {
            stripHtml: false,
            stripXml: false,
            stripJsonLikeWeirdness: false,
            blankCellVal: '',
            htmlEntities: false,
            // Some of the API calls return something other than an empty string when there's no data, make this true to remove those.
            turnNoValCellToBlankCell: false,
        }
        options = setDefaultArgsIfNoneProvided(defaultOptions, options)

        let headerNames: Record<string, string> = {}
        let formattedColumns = []

        // There's a +1 to i in here because the old csv export tool starts with "col1".
        for (let i = 0; i < headers.length; i++) {
            headerNames["col" + (i + 1).toString()] = headers[i]
        }

        for (let i = 0; i < data.length; i++) {
            let iObject: Record<string, string> = {}
            for (let j = 0; j < colKeys.length; j++) {
                if (typeof data[i][colKeys[j]] === 'undefined' || data[i][colKeys[j]] === null) data[i][colKeys[j]] = ''
                let columnValue = data[i][colKeys[j]]
                columnValue = columnValue.replaceAll(',', ';')
                columnValue = columnValue.replaceAll('\n', '')
                columnValue = columnValue.replaceAll('\r', '')
                //todo add in the dataH stripping
                if (options.htmlEntities) columnValue = strip.careful.removeHtmlEntities(columnValue)
                if (options.stripHtml) columnValue = strip.careful.html(columnValue)
                if (options.turnNoValCellToBlankCell) columnValue = columnValue.replaceAll('-Idle-', 'Idle')
                if (options.stripJsonLikeWeirdness) columnValue = strip.careless.apiJsonWeirdness(columnValue)

                iObject['col' + (j + 1).toString()] = columnValue
            }

            formattedColumns.push(iObject)
        }

        if (!fileName) {
            let now = new Date()
            fileName = now.toISOString().replaceAll('.', '')
        }

        exportCSVFile(headerNames, formattedColumns, fileName)
    }
    catch (e) {
        console.log(e)
    }
}

function exportCSVFile(headers: any, items: any, fileTitle: any) {
    if (headers) {
        items.unshift(headers)
    }

    // Convert Object to JSON
    let jsonObject = JSON.stringify(items)

    let csv = convertToCSV(jsonObject)
    let exportedFilename = fileTitle + '.csv'  //|| 'export.csv';
    const BOM = '\uFEFF';
    let blob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' })

    let link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob)
        link.setAttribute("href", url)
        link.setAttribute("download", exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

    }
}


function convertToCSV(objArray: any) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    let str = ''

    for (let i = 0; i < array.length; i++) {
        let line = ''
        for (let index in array[i]) {
            if (line !== '') line += ','
            line += array[i][index]
        }
        str += line + '\r\n'
    }
    return str
}

export const strip = {
    careful: {
        html: function (str: string) {
            try {
                str = str
                    .replaceAll('<br>', '')
                    .replaceAll('</br>', '')
                    .replaceAll('<p>', '')
                    .replaceAll('</p>', '')
                    .replaceAll('<b>', '')
                    .replaceAll('</b>', '')
                    .replaceAll('&lt;', '<')
                    .replaceAll('&gt;', '>')
                return str
            } catch (e) {
                console.log('dataH.strip.careful.html input: ')
                console.log(str)
                console.error(e)
            }
        },
        xml: function (str: string) {
            // TODO: regex
            try {
                str = str.replaceAll('<[^>]+>', '')
                return str
            } catch (e) {
                console.log('dataH.strip.careful.xml input: ')
                console.log(str)
                console.error(e)
            }
        },
        removeHtmlEntities: function (inputString: string) {
            const doc = new DOMParser().parseFromString(inputString, 'text/html')
            return doc.body.textContent || ''
        },
    },
    careless: {
        // "stripJsonLikeWeirdness" refers to some of the API returns that have a bunch of stuff to make it work in data tables, or something. VSCN022A.oCVETests is an example.
        apiJsonWeirdness: function (str: string) {
            str = str.replaceAll('[', '')
            str = str.replaceAll('"', '')
            str = str.replaceAll('{', '')
            str = str.replaceAll('\\', '')
            return str
        },

        xml: function (str: string) {
            try {
                return str.replace(/<[^>]*>/g, '')
            } catch (e) {
                console.log('dataH.strip.careless.xml input: ')
                console.log(str)
                console.error(e)
            }
        },
    },
}


export const onClickGoToInstance = (item: any) => {
    preparePromise(apiType.get, '_MSPL001', { iCID: item.oCID }).then((resp) => {
        if (resp[0].oURL !== '') {
            var grantstring = resp[0].oURL.split('?')
            window.open(grantstring[0].split('/app')[0] + '?' + grantstring[1], '_blank');
        }
        else {
            notify.error(resp[0].oResponse);
        }
    })
}

export function validateEmailRegex(inputEmail: string) {
    const emailRegex = /^[\w+]+([\.-]?[\w+]+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    return emailRegex.test(inputEmail)
}

export function validatePasswordRegex(input: string) {
    const passRegex = /^(?!.*(.)\1\1\1)[0-9a-zA-Z_\-()!@]{8,}$/
    return passRegex.test(input)
}

export function validatePwdRegex(inputPwd: string) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\S_])[^\s]{8,}$/
    return passwordRegex.test(inputPwd)
}