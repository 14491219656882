import { createTheme } from "@mantine/core";


export const theme = createTheme({
    fontSizes: {
        xxl: '1.5rem',
        xxxl: '4.5rem',
    },
    other: {
        fontWeight: {
            light: '250',
            medLight: '400',
            med: '500',
            medHeavy: '600',
            heavy: '700',
        }
    }
});
